<template>
  <div class="ffg__key">
    <div class="ffg__title">
      <h1>КЛЮЧЕВЫЕ УЧАСТНИКИ ПРОЕКТА</h1>
    </div>
    <div class="container-grid grid-3 grid-md-1 mb-md-10">
      <div class="container-grid__item">
        <div class="container-shadow fill-white d-flex flex-column" :class="{open: showMore.rec}">
          <div class="ffg__key__more-info" v-if="showMore.rec">
            <div class="ffg__key__more-info__header">
              <img class="logo logo-rec" src="/pic/ffg/logo-rec-big.svg" />
              <a @click="showMore.rec = false" href="javascript:void(0)"><img src="/pic/ffg/close.svg" /></a>
            </div>
            Российский экспортный центр – государственный институт поддержки несырьевого экспорта (входит в Группу ВЭБ.РФ), консолидирующий группу компаний, предоставляющих российским экспортерам широкий спектр финансовых и нефинансовых мер поддержки.
          </div>
          <template v-else>
            <div class="ffg__key__icon ffg__key__icon-rec">
              <img src="/pic/ffg/logo-rec-big.svg" />
            </div>
            <div class="d-flex justify-content-end ffg__key__link ffg__key__link-rec">
              <a href="javascript:void(0)" @click="showMore.rec = true" class="link-target"><span>подробнее</span></a>
            </div>
          </template>
        </div>
      </div>
      <div class="container-grid__item">
        <div class="container-shadow fill-white d-flex flex-column" :class="{open: showMore.shrec}">
          <div class="ffg__key__more-info" v-if="showMore.shrec">
            <div class="ffg__key__more-info__header">
              <img class="logo logo-shrec" src="/pic/ffg/logo-shrec-big.svg" />
              <a @click="showMore.shrec = false" href="javascript:void(0)"><img src="/pic/ffg/close.svg" /></a>
            </div>
            Школа экспорта РЭЦ – лицензированная образовательная организация, созданная для реализации образовательных и акселерационных программ для российского бизнеса. Оказывает образовательную поддержку экспортно ориентированным предприятиям для выхода на международный уровень торговли и повышения эффективности экспортных поставок.
          </div>
          <template v-else>
            <div class="ffg__key__icon ffg__key__icon-shrec">
              <img src="/pic/ffg/logo-shrec-big.svg" />
            </div>
            <div class="d-flex justify-content-end ffg__key__link ffg__key__link-shrec">
              <a href="javascript:void(0)" @click="showMore.shrec = true" class="link-target"><span>подробнее</span></a>
            </div>
          </template>
        </div>
      </div>
      <div class="container-grid__item">
        <div class="container-shadow fill-white d-flex flex-column" :class="{open: showMore.br}">
          <div class="ffg__key__more-info" v-if="showMore.br">
            <div class="ffg__key__more-info__header">
              <img class="logo logo-br" src="/pic/ffg/logo-br-big.svg" />
              <a @click="showMore.br = false" href="javascript:void(0)"><img src="/pic/ffg/close.svg" /></a>
            </div>
            Банк России – главный денежно-кредитный регулятор страны. Эксперты банка обладают уникальной экспертизой и аналитикой в области финансов и финансовых мер поддержки российских компаний.
          </div>
          <template v-else>
            <div class="ffg__key__icon ffg__key__icon-br">
              <img src="/pic/ffg/logo-br-big.svg" />
            </div>
            <div class="d-flex justify-content-end ffg__key__link-br">
              <a href="javascript:void(0)" @click="showMore.br = true" class="link-target"><span>подробнее</span></a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FfgKey",
  data() {
    return {
      showMore: {
        rec: false,
        shrec: false,
        br: false
      }
    }
  }
}
</script>

<style lang="scss">
.ffg {

}
</style>