<template>
  <div class="modal fade ffg__modal" id="request-modal" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" :class="{'form-result': isSubmit}">
        <template v-if="!isSubmit">
          <div class="modal-header">
            <div class="title-with-icon">
              <div class="title-with-icon__title">
                Заявка на уведомление
              </div>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Мы уведомим Вас, когда пособие и/или онлайн курс будут готовы.<br/>
            Для связи с Вами просим оставить заявку на уведомление.
            <form @submit.prevent="submit">
              <div class="form-item">
                <label>Имя Фамилия</label>
                <input :disabled="isLoaded" class="form-control" v-model="form.fullname" required placeholder="Введите имя" name="name" />
              </div>
              <div class="form-item">
                <label>Email</label>
                <input :disabled="isLoaded" class="form-control" v-model="form.email" required type="email" placeholder="Введите почту" name="name" />
              </div>
              <div class="form-item">
                <label>Выберите учебные материалы, о готовности которых Вас нужно уведомить:</label>
                <div class="checkbox-wrapper">
                  <input type="checkbox" v-model="form.typeManual" id="manual" />&nbsp;
                  <label for="manual">Учебное пособие</label>
                </div>
                <div class="checkbox-wrapper">
                  <input type="checkbox" v-model="form.typeOnline" id="course" />&nbsp;
                  <label for="course">Онлайн курс</label>
                </div>
                <small v-if="isError" class="text-red">Пожалуйста, выберите хотя бы один чекбокс!</small>
              </div>
              <div class="form-item">
                <button :disabled="isLoaded" type="submit" class="link-target"><span>Оставить заявку</span></button>
              </div>
            </form>
          </div>
        </template>
        <template v-else>
          <div class="modal-body">
            <div class="d-flex justify-content-between align-items-end">
              <div>
                <h2>Спасибо!</h2>
                <p>
                  Ваша заявка принята в работу!
                </p>
              </div>
              <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-primary btn-lg btn-rounded">OK</button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import 'bootstrap'
import {eventBus} from "@/main";
import ApiService from "../../../services/api.service";
export default {
  name: "FgRequestModal",
  data() {
    return {
      isSubmit: false,
      isLoaded: false,
      form: {}
    }
  },
  mounted() {
    $('#request-modal').modal('show');
    $('#request-modal').on('hide.bs.modal', function () {
      eventBus.$emit('hideModal')
    })
  },
  methods: {
    submit() {

      if(this.isError) {
        return;
      }

      this.isLoaded = true
      ApiService.post('/ffg/requests', this.form).then(() => {
        this.isSubmit = true
        this.isLoaded = false
      }).catch(() => {
        this.isLoaded = false
      })
    },
  },
  computed: {
    isError() {
      return !this.form.typeOnline && !this.form.typeManual
    }
  }
}
</script>