<template>
  <div class="ffg__content">
    <div class="row">
      <div class="col-md-7">
        <h1>СОДЕРЖАНИЕ ОЧНОГО КУРСА</h1>
        <div class="ffg__content__text mb-48">
          В образовательных материалах курса в понятной, простой и практичной форме мы говорим на актуальные для бизнеса темы:
        </div>
      </div>
      <div class="col-md-5">
        <div class="ffg__content__text">
          <img src="/pic/ffg/calc.svg" />
        </div>
      </div>
    </div>
    <div class="ffg__content-container container-shadow fill-white" :class="{'mb-24': items.length > index + 1}" v-for="(item, index) in items" :key="index">
      <h2>{{ item.title }}</h2>
      <ul class="circle-list pink-ball">
        <li v-for="(theme, key) in item.themes" :key="key">{{ theme }}</li>
      </ul>
      <div class="ffg__content-number" :class="{'ffg__content-number': index > 0, 'ffg__content-number-one': index === 0}">{{ index + 1 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FfgContent",
  data() {
    return {
      items: [
        {
          title: 'Финансовое планирование и анализ:',
          themes: [
            'Управление финансами на простых примерах',
            'Инструменты финансового планирования',
            'Ценообразование и расчет рентабельности бизнеса',
            'Юнит-экономика для экспортеров'
          ]
        },
        {
          title: 'Налогообложение:',
          themes: [
            'Преимущества типов налоговых систем для развития и масштабирования бизнеса',
            'Налоговая нагрузка и работа с налоговыми рисками',
            'Подтверждение нулевой ставки НДС для экспортеров'
          ]
        },
        {
          title: 'Бухгалтерский учет:',
          themes: [
            'Организация бухучета без специального образования',
            'Первичные документы и электронный документооборот',
            'Учет курсовой разницы при проведении расчетов в иностранной валюте'
          ]
        },
        {
          title: 'Работа с финансовыми организациями:',
          themes: [
            'Привлечение внешнего финансирования: кредиты, гранты, лизинг, факторинг',
            'Выстраивание стратегии бизнеса под работу с финансовыми организациями'
          ]
        },
        {
          title: 'Финансовая поддержка:',
          themes: [
            'Федеральные и региональные специализированные программы поддержки бизнеса',
            'Финансовые инструменты поддержки при экспорте'
          ]
        }
      ]
    }
  }
}
</script>