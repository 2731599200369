<template>
  <div class="ffg__timeline">
    <fg-order-modal v-if="showOrderModal" />
    <fg-request-modal v-if="showRequestModal" />
    <h1>ТАЙМЛАЙН РАЗВИТИЯ ПРОЕКТА</h1>
    <div class="ffg__timeline__line">
      <div class="ffg__timeline__line-start"></div>
      <div class="ffg__timeline__line-middle"></div>
      <div class="ffg__timeline__line-end"></div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="ffg__timeline__header first">I  кв. 2024 год</div>
        <h2>Учебное пособие
          <br/>в цифровом формате</h2>
        <p>Будет готово в I кв. 2024 года</p>
        <div class="ffg__timeline__text width_75">
          Пока идет разработка вы можете ознакомиться с <a target="_blank" href="https://www.exportcenter.ru/services/obrazovatelnye-uslugi/uchebnoe_posobie_gendernye_osobennosti_mezhkulturnoy_kommunikatsii/">пособием для женщин-предпринимательниц о деловой коммуникации с зарубежными партнерами  с учетом гендерных аспектов сторон переговоров</a>.
        </div>
        <hr class="width_50"/>
        <div class="ffg__timeline__text width_80">
          Оставьте заявку, чтобы узнать о готовности пособия и его стоимости!
        </div>
        <a href="javascript:void(0)" @click="showRequestModal = true" class="link-target"><span>оставить заявку</span></a>
      </div>
      <div class="col-md-4">
        <div class="ffg__timeline__header">2024 год</div>
        <h2>Очный курс <br/>«Женское дело»</h2>
        <div class="ffg__timeline__text mt-2">
          Оставьте заявку, чтобы он прошел в вашем регионе! Мы выберем регионы с наибольшим числом заявок и проведем очное обучение в 2024 году! <span class="text-blue font-weight-bold">Участницы обучения получат цифровое учебное пособие бесплатно!</span>
          <br/>
          <br/>
          Участие платное.
        </div>
        <a href="javascript:void(0)" @click="showOrderModal = true" class="link-target"><span>оставить заявку</span></a>
      </div>
      <div class="col-md-4">
        <div class="ffg__timeline__header">IV кв. 2024 год</div>
        <h2>Онлайн курс на основе программы очного обучения</h2>
        <p>Будет готов в IV кв. 2024 года</p>
        <hr class="width_50"/>
        <div class="ffg__timeline__text width_75">
          Оставьте заявку, чтобы узнать <br/>о готовности курса и его стоимости!
        </div>
        <a href="javascript:void(0)" @click="showRequestModal = true" class="link-target"><span>оставить заявку</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import FgOrderModal from "@/components/ffg/FgOrderModal.vue";
import {eventBus} from "@/main";
import FgRequestModal from "@/components/ffg/FgRequestModal.vue";

export default {
  name: "FfgTimeline",
  components: {FgRequestModal, FgOrderModal},
  data() {
    return {
      showOrderModal: false,
      showRequestModal: false
    }
  },
  mounted() {
    tippy('#tooltipFgg', {
      content: 'Тест включает в себя блоки вопросов по разным темам экспортной деятельности и позволит комплексно оценить Ваши знания, а также подготовить для Вас рекомендации по дальнейшему обучению.',
      arrow: true
  });
    eventBus.$on('hideModal', () => {
      this.showOrderModal = false
      this.showRequestModal = false
    })
  }
}
</script>