<template>
  <div class="ffg__mission">
    <div class="ffg__title ffg__title-mission">
      <h1>МИССИЯ ПРОГРАММЫ</h1>
    </div>
    <div class="row">
      <div class="col-md-6 ffg__mission__icon">
        <img class="show-lg" src="/pic/ffg/icon-mission.svg" />
        <img class="show-md" src="/pic/ffg/icon-mission-mobile.svg" />
      </div>
      <div class="col-md-6 ffg__mission__list">
        <h2>Наша миссия</h2>
        <ul>
          <li>Разработка максимально практичных и полезных инструментов для женской аудитории</li>
          <li>Анализ запросной позиции и «женского взгляда» на организацию бизнеса</li>
          <li>Развитие экспортной деятельности</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FfgMission"
}
</script>