<template>
  <div class="modal fade ffg__modal" id="order-modal" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" :class="{'form-result': isSubmit}">
        <template v-if="!isSubmit">
          <div class="modal-header">
            <div class="title-with-icon">
              <div class="title-with-icon__title">
                Заявка на участие в обучении
              </div>
            </div>
            <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Для участия в очном курсе «Женское дело» необходимо оставить заявку. Мы выберем регионы с наибольшим числом заявок и проведем очное обучение в 2024 году!
            <form @submit.prevent="submit">
              <div class="form-item">
                <label>Имя Фамилия</label>
                <input :disabled="isLoaded" class="form-control" v-model="form.fullname" required placeholder="Введите имя" name="fullname" />
              </div>
              <div class="form-item">
                <label for="phone">Мобильный телефон</label>
                <input :disabled="isLoaded" v-model="form.phone" v-imask="{mask: '+ {7} (000) 000 0000',lazy: true}" :class="{'is-invalid': errors.phone}" class="form-control" id="phone" required placeholder="+7 (999) 999 9999" />
                <div class="invalid-feedback">
                  Проверьте правильность ввода номера
                </div>
              </div>
              <div class="form-item">
                <label>Email</label>
                <input :disabled="isLoaded" class="form-control" required placeholder="Введите почту" v-model="form.email" type="email" name="email" />
              </div>
              <div class="form-item" v-if="false">
                <div class="form-check form-check-inline">
                  <input :disabled="isLoaded" v-model="form.type" required class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="offline">
                  <label class="form-check-label" for="inlineRadio1">Очное участие</label>
                </div>
                <div class="form-check form-check-inline">
                  <input :disabled="isLoaded" v-model="form.type" required class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="online">
                  <label class="form-check-label" for="inlineRadio2">Дистанционное участие</label>
                </div>
              </div>
              <div class="form-item">
                <button :disabled="isLoaded" type="submit" class="link-target"><span>Оставить заявку</span></button>
              </div>
            </form>
          </div>
        </template>
        <template v-else>
          <div class="modal-body">
            <div class="d-flex justify-content-between align-items-end">
              <div>
                <h2>Спасибо!</h2>
                <p>
                  Ваша заявка принята в работу!
                </p>
              </div>
              <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-primary btn-lg btn-rounded">OK</button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from 'vue-imask';
import $ from "jquery";
import 'bootstrap'
import {eventBus} from "@/main";
import ApiService from "../../../services/api.service";
export default {
  name: "FgOrderModal",
  directives: {
    imask: IMaskDirective
  },
  data() {
    return {
      isSubmit: false,
      isLoaded: false,
      form: {
        type: 'offline'
      },
      errors: {
        phone: false
      },
    }
  },
  mounted() {
    $('#order-modal').modal('show');
    $('#order-modal').on('hide.bs.modal', function () {
      eventBus.$emit('hideModal')
    })
  },
  methods: {
    submit() {
      if(this.validate()) {
        this.isLoaded = true
        ApiService.post('/ffg/orders', this.form).then(() => {
          this.isSubmit = true
          this.errors.phone = false
          this.isLoaded = false
        }).catch(() => {
          this.isLoaded = false
        })
      }
    },
    validate() {
      if(!this.form.phone || this.form.phone.length < 18) {
        this.errors.phone = true
        return false;
      }
      return true;
    }
  }
}
</script>