var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ffg__timeline"},[(_vm.showOrderModal)?_c('fg-order-modal'):_vm._e(),(_vm.showRequestModal)?_c('fg-request-modal'):_vm._e(),_c('h1',[_vm._v("ТАЙМЛАЙН РАЗВИТИЯ ПРОЕКТА")]),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ffg__timeline__header first"},[_vm._v("I кв. 2024 год")]),_vm._m(1),_c('p',[_vm._v("Будет готово в I кв. 2024 года")]),_vm._m(2),_c('hr',{staticClass:"width_50"}),_c('div',{staticClass:"ffg__timeline__text width_80"},[_vm._v(" Оставьте заявку, чтобы узнать о готовности пособия и его стоимости! ")]),_c('a',{staticClass:"link-target",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showRequestModal = true}}},[_c('span',[_vm._v("оставить заявку")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ffg__timeline__header"},[_vm._v("2024 год")]),_vm._m(3),_vm._m(4),_c('a',{staticClass:"link-target",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showOrderModal = true}}},[_c('span',[_vm._v("оставить заявку")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ffg__timeline__header"},[_vm._v("IV кв. 2024 год")]),_c('h2',[_vm._v("Онлайн курс на основе программы очного обучения")]),_c('p',[_vm._v("Будет готов в IV кв. 2024 года")]),_c('hr',{staticClass:"width_50"}),_vm._m(5),_c('a',{staticClass:"link-target",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showRequestModal = true}}},[_c('span',[_vm._v("оставить заявку")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ffg__timeline__line"},[_c('div',{staticClass:"ffg__timeline__line-start"}),_c('div',{staticClass:"ffg__timeline__line-middle"}),_c('div',{staticClass:"ffg__timeline__line-end"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Учебное пособие "),_c('br'),_vm._v("в цифровом формате")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ffg__timeline__text width_75"},[_vm._v(" Пока идет разработка вы можете ознакомиться с "),_c('a',{attrs:{"target":"_blank","href":"https://www.exportcenter.ru/services/obrazovatelnye-uslugi/uchebnoe_posobie_gendernye_osobennosti_mezhkulturnoy_kommunikatsii/"}},[_vm._v("пособием для женщин-предпринимательниц о деловой коммуникации с зарубежными партнерами с учетом гендерных аспектов сторон переговоров")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Очный курс "),_c('br'),_vm._v("«Женское дело»")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ffg__timeline__text mt-2"},[_vm._v(" Оставьте заявку, чтобы он прошел в вашем регионе! Мы выберем регионы с наибольшим числом заявок и проведем очное обучение в 2024 году! "),_c('span',{staticClass:"text-blue font-weight-bold"},[_vm._v("Участницы обучения получат цифровое учебное пособие бесплатно!")]),_c('br'),_c('br'),_vm._v(" Участие платное. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ffg__timeline__text width_75"},[_vm._v(" Оставьте заявку, чтобы узнать "),_c('br'),_vm._v("о готовности курса и его стоимости! ")])
}]

export { render, staticRenderFns }