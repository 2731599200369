<template>
  <div class="ffg">
    <div class="section section__ffg">
    <div class="section__wrapper">
      <div class="row">
        <div class="col-md-8">
          <div class="ffg__title">
            <h1 class="main-title">ЖЕНСКОЕ ДЕЛО</h1>
            <img src="/pic/ffg/icon-girl.svg" />
          </div>
          <div class="ffg__text">
            Уникальная программа, разработанная Школой экспорта РЭЦ<br class="d-none d-md-block" />совместно с Группой Российского экспортного центра и Банком России.
          </div>
        </div>
        <div class="col-md-4">
          <div class="ffg__girls-chart">
            <img src="/pic/ffg/girls-chat.svg" />
          </div>
        </div>
      </div>
      <ffg-carousel />
      <ffg-chat v-if="false"/>
      <ffg-mission/>
      <ffg-content/>
      <ffg-timeline/>
      <ffg-key/>
      <ffg-test/>
    </div>
  </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../components/PageFooter";
import FfgChat from "@/components/ffg/FfgChat.vue";
import FfgMission from "@/components/ffg/FfgMission.vue";
import FfgKey from "@/components/ffg/FfgKey.vue";
import FfgContent from "@/components/ffg/FfgContent.vue";
import FfgTimeline from "@/components/ffg/FfgTimeline.vue";
import FfgCarousel from "@/components/ffg/FfgCarousel.vue";
import FfgTest from "@/components/ffg/FfgTest.vue";

export default {
  name: "FinForGirls",
  components: {FfgTest, FfgCarousel, FfgTimeline, FfgContent, FfgKey, FfgMission, FfgChat, PageFooter},
  mounted() {
    this.$parent.init()
  }
}
</script>