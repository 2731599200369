<template>
  <div class="ffg__chat container-shadow fill-white">
    <div class="d-flex">
      <div class="ffg__chat__avatar ffg__chat__avatar-red">
        <img src="/pic/ffg/icon-girl-circle.svg" />
      </div>
      <div class="ffg__chat__chat ffg__chat__chat-red">
        <div class="ffg__chat__chat-red__arrow"></div>
        Добрый день! Подскажите, какие знания и навыки можно получить, участвуя в проекте? Участие платное?
      </div>
    </div>
    <div class="d-flex">
      <div class="ffg__chat__chat ffg__chat__chat-blue">
        <div class="ffg__chat__chat-blue__arrow"></div>
        Здравствуйте! В проекте мы рассказываем об основах бухгалтерского учета, налогообложения, финансового планирования и анализа,           <br/>а также о финансовой поддержке предпринимательниц. Участие бесплатное!
      </div>
      <div class="ffg__chat__avatar ffg__chat__avatar-blue">
        <img src="/pic/ffg/icon-rec.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FfgChat"
}
</script>
