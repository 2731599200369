<template>
  <div class="ffg__timeline">
    <div class="container-shadow fill-white">
      <div class="row">
        <div class="col-md-6">
          <img src="/pic/ffg/womens.png" />
        </div>
        <div class="col-md-6 tooltip-block">
          <div id="tooltipFgg" class="tooltip-button"></div>
          <h2>Пройдите тест, и мы подберем для вас дополнительные программы обучения!</h2>
          <div class="d-flex justify-content-end">
            <a target="_blank" href="https://exportedu.ru/profile/recommend" class="link-target"><span>пройти тест</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FfgTest"
}
</script>

<style scoped>

</style>