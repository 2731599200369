<template>
  <div class="ffg__carousel container-shadow fill-white">
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.4519 17.5187C33.0192 26.751 25.1842 33.8845 15.9519 33.4519C6.71954 33.0192 -0.413983 25.1842 0.0186864 15.9518C0.451356 6.71954 8.28637 -0.413983 17.5187 0.0186864C26.751 0.451356 33.8845 8.28637 33.4519 17.5187Z" fill="#1C75BC"/>
      <circle cx="17" cy="17" r="15" fill="#FAFAFA"/>
      <path d="M11.0933 15.548C10.4693 14.972 10.1573 14.234 10.1573 13.334C10.1573 12.434 10.4633 11.702 11.0753 11.138C11.7113 10.574 12.5033 10.292 13.4513 10.292C14.3993 10.292 15.1853 10.574 15.8093 11.138C16.4333 11.69 16.7453 12.422 16.7453 13.334C16.7453 14.234 16.4333 14.972 15.8093 15.548C15.1733 16.112 14.3873 16.394 13.4513 16.394C12.5153 16.394 11.7293 16.112 11.0933 15.548ZM11.5073 13.334C11.5073 13.886 11.6933 14.342 12.0653 14.702C12.4493 15.05 12.9113 15.224 13.4513 15.224C14.0033 15.224 14.4653 15.05 14.8373 14.702C15.2093 14.342 15.3953 13.886 15.3953 13.334C15.3953 12.794 15.2093 12.35 14.8373 12.002C14.4653 11.642 14.0033 11.462 13.4513 11.462C12.8993 11.462 12.4373 11.642 12.0653 12.002C11.6933 12.35 11.5073 12.794 11.5073 13.334ZM18.6713 22.262C18.0473 21.686 17.7353 20.948 17.7353 20.048C17.7353 19.148 18.0413 18.416 18.6533 17.852C19.2893 17.288 20.0813 17.006 21.0293 17.006C21.9773 17.006 22.7633 17.288 23.3873 17.852C24.0113 18.404 24.3233 19.136 24.3233 20.048C24.3233 20.948 24.0113 21.686 23.3873 22.262C22.7513 22.826 21.9653 23.108 21.0293 23.108C20.0933 23.108 19.3073 22.826 18.6713 22.262ZM19.0853 20.048C19.0853 20.6 19.2713 21.056 19.6433 21.416C20.0273 21.764 20.4893 21.938 21.0293 21.938C21.5813 21.938 22.0433 21.764 22.4153 21.416C22.7873 21.056 22.9733 20.6 22.9733 20.048C22.9733 19.508 22.7873 19.064 22.4153 18.716C22.0433 18.356 21.5813 18.176 21.0293 18.176C20.4773 18.176 20.0153 18.356 19.6433 18.716C19.2713 19.064 19.0853 19.508 19.0853 20.048ZM13.0553 23.18L12.2093 22.586L21.4613 10.22L22.2893 10.832L13.0553 23.18Z" fill="#D76285"/>
    </svg>

    <div class="d-flex justify-content-between align-items-center mt-2 row">
      <div class="col-md-4 text-blue col-xs-12">
        <div class="d-none d-md-block">
          <p class="percent-title">98%</p>
          <p class="percent-people">ОБУЧАЮЩИХСЯ</p>
          <p class="percent-text">высоко оценили качество обучения</p>
        </div>
        <div class="d-block d-md-none mb-10">
          <p class="percent-people">98% ОБУЧАЮЩИХСЯ</p>
          <p class="percent-text">высоко оценили качество обучения</p>
        </div>
      </div>
      <div class="col-md-8 col-xs-12 pr-0 pl-0">
        <carousel
            :loop="true"
            :per-page="1"
            :pagination-enabled="false"
            :scroll-per-page="false"
            :navigation-enabled="true"
            :navigation-next-label="navigationNext"
            :navigation-prev-label="navigationPrev"
            @transition-end="onSlide"
            ref="homeCarrousel"
            :per-page-custom="[[420, 2], [1024, 3]]"
            class="d-flex">
          <slide v-for="(item, index) in items" :key="index">
            <div class="ffg__carousel__item">
              <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.293 22.8132C27.1811 22.8132 27.0739 22.7691 26.9948 22.6906C26.9157 22.612 26.8712 22.5055 26.8712 22.3944V18.2683C26.8712 16.5974 26.3777 15.3275 25.4668 14.5032C24.5908 13.7704 23.4706 13.389 22.3261 13.4338H22.187C21.0303 13.3851 19.8971 13.7682 19.0104 14.5076C18.1131 15.3364 17.6374 16.6019 17.615 18.2728V22.3944C17.615 22.5055 17.5706 22.612 17.4915 22.6906C17.4124 22.7691 17.3051 22.8132 17.1933 22.8132C17.0814 22.8132 16.9741 22.7691 16.895 22.6906C16.816 22.612 16.7715 22.5055 16.7715 22.3944V18.2683C16.7984 16.3479 17.3593 14.873 18.4496 13.8883C19.4949 12.995 20.8455 12.534 22.2229 12.6006H22.2947C23.6601 12.5476 24.9951 13.0092 26.0322 13.8927C27.1225 14.8775 27.6878 16.3523 27.7102 18.2683V22.3988C27.7102 22.5092 27.6664 22.615 27.5883 22.6935C27.5102 22.7719 27.4041 22.8165 27.293 22.8177" fill="#D76285"/>
                <path d="M19.6605 18.567C19.6623 18.5774 19.6623 18.5879 19.6605 18.5982C19.6605 20.7237 20.9526 22.809 22.2762 22.809C23.5998 22.809 24.7171 20.844 24.7171 18.6071C24.743 17.9552 24.6186 17.306 24.3536 16.709C23.721 17.2214 22.2897 18.1126 19.656 18.567M22.2762 23.6422C20.4815 23.6422 18.8573 21.294 18.8169 18.6918C18.3683 18.7497 17.9196 18.7898 17.4081 18.821C17.3527 18.8242 17.2972 18.8166 17.2448 18.7985C17.1924 18.7804 17.1441 18.7523 17.1026 18.7157C17.0612 18.6791 17.0274 18.6347 17.0032 18.5851C16.979 18.5355 16.9649 18.4817 16.9617 18.4267C16.9584 18.3717 16.9661 18.3166 16.9843 18.2645C17.0025 18.2125 17.0309 18.1645 17.0677 18.1233C17.1046 18.0822 17.1493 18.0486 17.1992 18.0246C17.2491 18.0006 17.3034 17.9866 17.3587 17.9833C22.4198 17.7026 24.0889 15.8089 24.1158 15.7911C24.1578 15.7449 24.2089 15.708 24.2661 15.6827C24.3233 15.6574 24.3852 15.6442 24.4479 15.644C24.5105 15.644 24.5724 15.6585 24.6284 15.6864C24.6845 15.7142 24.7333 15.7546 24.7709 15.8044C25.6682 16.9184 25.5651 18.5626 25.5606 18.6339C25.5606 21.04 24.2414 23.6422 22.2762 23.6422Z" fill="#D76285"/>
                <path d="M14.1153 32.9237C14.0597 32.9209 14.0051 32.9071 13.955 32.883C13.9048 32.8589 13.8601 32.8251 13.8233 32.7836C13.7865 32.7421 13.7584 32.6937 13.7407 32.6412C13.7231 32.5887 13.7162 32.5333 13.7205 32.4781L14.057 27.2158C14.3935 25.077 16.8298 24.5735 16.933 24.5423L18.7995 24.1413C18.8555 24.1237 18.9148 24.1178 18.9732 24.1242C19.0317 24.1306 19.0882 24.1491 19.1391 24.1785C19.19 24.2079 19.2341 24.2475 19.2686 24.2948C19.3032 24.3421 19.3273 24.3961 19.3396 24.4532C19.3518 24.5104 19.3519 24.5695 19.3398 24.6267C19.3277 24.6838 19.3037 24.7379 19.2693 24.7853C19.235 24.8327 19.1909 24.8725 19.1401 24.902C19.0894 24.9315 19.0329 24.9502 18.9745 24.9567L17.1035 25.3622C17.0766 25.3622 15.1517 25.7677 14.8915 27.3139L14.5595 32.5138C14.5537 32.6205 14.5066 32.721 14.428 32.794C14.3494 32.8671 14.2454 32.9072 14.1377 32.9059" fill="#D76285"/>
                <path d="M22.2364 30.9805C22.1494 30.9798 22.0648 30.9525 21.9942 30.9023C21.9235 30.852 21.8702 30.7813 21.8415 30.6998L19.5981 24.5597L18.0906 26.3153H18.8488C18.9607 26.3153 19.068 26.3594 19.1471 26.438C19.2262 26.5165 19.2706 26.6231 19.2706 26.7341C19.2706 26.8452 19.2262 26.9518 19.1471 27.0303C19.068 27.1089 18.9607 27.153 18.8488 27.153H17.1798C17.099 27.1534 17.0198 27.1308 16.9516 27.0878C16.8835 27.0448 16.8291 26.9833 16.7951 26.9106C16.7611 26.8379 16.7488 26.757 16.7597 26.6775C16.7707 26.598 16.8043 26.5234 16.8567 26.4623L19.4321 23.468C19.4794 23.4117 19.5408 23.3688 19.6102 23.3438C19.6796 23.3187 19.7543 23.3124 19.827 23.3255C19.8994 23.3392 19.9668 23.3715 20.0227 23.4192C20.0786 23.4669 20.121 23.5283 20.1455 23.5973L22.6087 30.4191C22.628 30.4706 22.6368 30.5253 22.6345 30.5801C22.6323 30.635 22.6191 30.6889 22.5957 30.7386C22.5723 30.7884 22.5393 30.833 22.4984 30.8699C22.4575 30.9069 22.4096 30.9354 22.3575 30.9538C22.3117 30.9712 22.263 30.9803 22.2139 30.9805" fill="#D76285"/>
                <path d="M30.3663 32.9237C30.2595 32.9238 30.1566 32.8832 30.079 32.8103C30.0013 32.7373 29.9548 32.6376 29.9491 32.5316L29.617 27.3316C29.3568 25.7855 27.4275 25.3845 27.4096 25.38L25.5296 24.9745C25.4717 24.967 25.416 24.9476 25.366 24.9176C25.3161 24.8876 25.2729 24.8476 25.2393 24.8001C25.2057 24.7527 25.1824 24.6988 25.1709 24.6419C25.1594 24.5851 25.1599 24.5264 25.1723 24.4698C25.1848 24.4131 25.209 24.3596 25.2434 24.3128C25.2777 24.2659 25.3215 24.2266 25.372 24.1973C25.4224 24.1681 25.4785 24.1497 25.5365 24.1431C25.5945 24.1365 25.6533 24.142 25.7091 24.1591L27.5801 24.5601C27.6743 24.5601 30.1106 25.0815 30.4516 27.2336V27.2693L30.7836 32.4959C30.7873 32.5509 30.7799 32.6061 30.7621 32.6583C30.7442 32.7106 30.7161 32.7587 30.6794 32.8001C30.6427 32.8415 30.5982 32.8753 30.5483 32.8996C30.4984 32.9239 30.4442 32.9381 30.3888 32.9415H30.3619" fill="#D76285"/>
                <path d="M22.2453 30.9806C22.1977 30.9804 22.1505 30.9713 22.1062 30.9539C22.0009 30.9166 21.9149 30.8393 21.867 30.7391C21.819 30.6388 21.8131 30.5238 21.8505 30.4192L24.3137 23.5974C24.3409 23.5292 24.3846 23.4687 24.441 23.4212C24.4973 23.3738 24.5645 23.341 24.6368 23.3256C24.7094 23.313 24.784 23.3195 24.8533 23.3446C24.9226 23.3696 24.9841 23.4122 25.0316 23.4681L27.6025 26.4624C27.6518 26.5245 27.6837 26.5985 27.6947 26.6768C27.7058 26.7551 27.6956 26.8349 27.6653 26.908C27.6317 26.9803 27.5779 27.0414 27.5103 27.084C27.4426 27.1267 27.3641 27.1491 27.284 27.1486H25.6104C25.4985 27.1486 25.3913 27.1045 25.3122 27.026C25.2331 26.9474 25.1886 26.8409 25.1886 26.7298C25.1886 26.6187 25.2331 26.5122 25.3122 26.4336C25.3913 26.3551 25.4985 26.3109 25.6104 26.3109H26.3731L24.8611 24.5554L22.6447 30.6955C22.6157 30.7777 22.5617 30.8489 22.4902 30.8992C22.4186 30.9495 22.333 30.9764 22.2453 30.9762" fill="#D76285"/>
                <circle cx="22.5" cy="23.0212" r="22" stroke="#D76285"/>
              </svg>
              <div class="ffg__carousel__item__text">&laquo;{{ item }}&raquo;</div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

const EXTRA = [
  'Очень насыщенная программа, много практики, хорошие спикеры!',
  'Финансовое планирование – тема для меня сложная, но я поняла суть и смогла посчитать свой проект, увидела плюсы и минусы, где что надо добавить и убрать',
  'Программа дала понимание, как думают госструктуры, банки, налоговая, чтобы потом было легко с ними взаимодействовать и получать поддержку',
  'Много информации об актуальных финансовых инструментах и мерах государственной поддержки',
  'В доступной форме объяснили сложные вещи и разложили все по полочкам. Спасибо!',
  'Программа дала понимание того, какую систему налогообложения выбрать для моего нового проекта!',
]
export default {
  name: "FfgCarousel",
  components: { Carousel, Slide },
  data() {
    return {
      items: EXTRA
    }
  },
  methods: {
    onSlide() {
      if(this.$refs.homeCarrousel.currentPage % this.$refs.homeCarrousel.perPage === 0) {
        this.$refs.homeCarrousel.$el.getElementsByTagName("button")[1].disabled = true
        this.items = this.items.concat(EXTRA)
        this.$refs.homeCarrousel.$el.getElementsByTagName("button")[1].disabled = false
      }
    }
  },
  computed: {
    navigationNext() {
      return '<svg width="45" height="36" viewBox="0 0 45 36" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M45.0001 18.018L26.4833 35.5212L22.807 32.0461L35.0503 20.4793L6.10352e-05 20.4793L6.10352e-05 15.5632L35.0503 15.5632L22.807 3.99638L26.4833 0.52124L45.0001 18.018Z" fill="#D76285"/>\n' +
          '</svg>'
    },
    navigationPrev() {
      return '<svg width="45" height="36" viewBox="0 0 45 36" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M6.25648e-05 18.0244L18.5168 0.521238L22.1931 3.99638L9.94985 15.5632L45.0001 15.5632L45.0001 20.4793L9.94985 20.4793L22.1931 32.0461L18.5168 35.5212L6.25648e-05 18.0244Z" fill="#D76285"/>\n' +
          '</svg>'
    }
  },
}
</script>

<style scoped>

</style>